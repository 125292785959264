import PageTitle from "../../helpers/PageTitle"
import InviteContextProvider from "./InviteContext"
import InviteList from "./InviteList"
import InviteNew from "./InviteNew"

const InviteView = () => {
  return (
    <InviteContextProvider>
      <PageTitle>Коды приглашения</PageTitle>

      <InviteNew />

      <InviteList />
    </InviteContextProvider>
  )
}

export default InviteView
