import { useState } from "react"
import AddNewBox from "../../helpers/AddNewBox"
import Box from "../../helpers/Box"
import Button from "../../helpers/Button"
import Input from "../../helpers/Input"
import Select from "../../helpers/Select"
import { useSessionContext } from "../../user/SessionContext"
import { userTypesOptions } from "../../util"
import { useInviteContext } from "./InviteContext"

const InviteNew = () => {
  const { create } = useInviteContext()
  const { user } = useSessionContext()

  const [mode, setMode] = useState("view")
  const [email, setEmail] = useState("")
  const [selectedType, setSelectedType] = useState("")

  const clearForm = () => {
    setEmail("")
    setSelectedType("")

    setMode("view")
  }

  if (mode === "view")
    return (
      <AddNewBox
        text="Добавить новый код приглашения"
        onClick={() => setMode("add")}
      />
    )

  return (
    <Box>
      <div className="flex flex-row mb-3 gap-4">
        <div className="w-full">
          <Input
            labelText="Email"
            helpText="обязательное поле"
            placeholder="user@aimfond.ru"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>

        <div className="w-full">
          <Select
            labelText="Тип пользователя"
            helpText="обязательное поле"
            placeholder="Выберите тип пользователя"
            options={userTypesOptions}
            selectedOption={selectedType}
            setSelectedOption={setSelectedType}
          />
        </div>
      </div>

      <div className="flex flex-row gap-2 justify-end mt-3">
        <Button size="" onClick={() => setMode("view")}>
          Отменить
        </Button>
        <Button
          size=""
          color="link"
          onClick={() =>
            create(
              {
                email,
                userType: selectedType,
                author_id: user?.id,
              },
              clearForm
            )
          }
        >
          Добавить
        </Button>
      </div>
    </Box>
  )
}

export default InviteNew
