const Box = ({ children, wrapped = false }) => {
  return (
    <div
      className="container bg-white text-slate-600 py-3 px-5 mx-auto mb-auto mt-4 shadow-lg rounded-lg "
      style={{ whiteSpace: wrapped ? "pre-wrap" : "normal" }}
    >
      {children}
    </div>
  )
}

export default Box
