import { useEffect } from "react"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import DossierView from "./dossier/DossierView"
import ProfileView from "./profile/ProfileView"
import SideBar from "./navbars/SideBar"
import { useSessionContext } from "./user/SessionContext"
import HomeView from "./home/HomeView"
import ManageView from "./manage/ManageView"

const RestrictedArea = () => {
  const { path } = useRouteMatch()
  const { getProfile } = useSessionContext()

  useEffect(() => {
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex">
      <SideBar />

      <Switch>
        <Route exact path={path}>
          <HomeView />
        </Route>

        <Route path={path + "/dossier"}>
          <DossierView />
        </Route>

        <Route path={path + "/manage"}>
          <ManageView />
        </Route>

        <Route path={path + "/profile"}>
          <ProfileView />
        </Route>
      </Switch>
    </div>
  )
}

export default RestrictedArea
