import { toast } from "react-toastify"

export const handleError = (code = "", placeholder = "") => {
  if (code?.length) return toast.error(errorCodes[code])

  if (placeholder?.length) return toast.error(placeholder)

  toast.error("Ошибка сервера")
}

const errorCodes = {
  not_found_or_invalid_invite:
    "Код приглашения недействителен или не существует",
  email_or_password_is_invalid: "Неправильный логин и/или пароль",
}
