import MenuBar, { MenuEntry } from "../navbars/MenuBar"
import ContentContainer from "../helpers/ContentContainer"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import OrganizationView from "./organizations/OrganizationView"
import PersonView from "./persons/PersonView"

const DossierView = () => {
  const { path, url } = useRouteMatch()

  return (
    <>
      <MenuBar>
        <MenuEntry text="Персоны" link={url + "/person"} />
        <MenuEntry text="Организации" link={url + "/org"} />
      </MenuBar>

      <ContentContainer>
        <Switch>
          <Route exact path={path}>
            <Redirect to={path + "/person"} />
          </Route>

          <Route path={path + "/person"}>
            <PersonView />
          </Route>

          <Route path={path + "/org"}>
            <OrganizationView />
          </Route>
        </Switch>
      </ContentContainer>
    </>
  )
}

export default DossierView
