import axios from "axios"
import { useEffect, useState } from "react"
import { handleError } from "../../user/error"
import { API_URL } from "../../util"

export const useOrganizationOptions = () => {
  const [orgOptions, setOrgOptions] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchOrgOptions = async () => {
      try {
        const resp = await axios.get(`${API_URL}/orgs`)
        const { status, code, data } = resp.data

        if (status === "error") return handleError(code)

        setOrgOptions(
          (data || []).map(org => ({ value: org?.id, label: org?.name }))
        )
      } catch (error) {
        handleError()
      }
    }

    fetchOrgOptions()
    setLoading(false)
  }, [])

  return { orgOptions, loading }
}
