import axios from "axios"
import { useEffect } from "react"
import { useState } from "react"
import { createContext, useContext } from "react"
import { toast } from "react-toastify"
import { handleError } from "../../user/error"
import { API_URL } from "../../util"

export const OrganizationContext = createContext()

const useOrganizations = () => {
  const [orgs, setOrgs] = useState([])
  const [loading, setLoading] = useState(true)
  const [nr, setNR] = useState(true)

  useEffect(() => {
    const fetchOrgs = async () => {
      try {
        const resp = await axios.get(`${API_URL}/orgs`)
        const { status, code, data } = resp.data

        if (status === "error") return handleError(code)

        setOrgs(data)
      } catch (error) {
        handleError()
      }
    }

    if (nr) {
      fetchOrgs()
      setLoading(false)
      setNR(false)
    }
  }, [nr])

  const refetch = (needsLoading = false) => {
    if (needsLoading) setLoading(true)

    setNR(true)
  }

  return { orgs, loading, refetch }
}

const OrganizationContextProvider = ({ children }) => {
  const { orgs, loading, refetch } = useOrganizations()

  const create = async (data, clearForm) => {
    try {
      const reqData = { organization: data }
      const resp = await axios.post(`${API_URL}/orgs`, reqData)
      const { status, code } = resp.data

      if (status === "error")
        return handleError(
          code,
          "Убедитесь, что все обязательные поля заполнены и такая организация уже не добавлена в систему"
        )

      refetch()
      clearForm()
      toast.success("Организация успешно добавлена")
    } catch (error) {
      handleError()
    }
  }

  const value = {
    create,
    orgs,
    loading,
    refetch,
  }

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  )
}

export const useOrganizationContext = () => {
  const context = useContext(OrganizationContext)

  return context
}

export default OrganizationContextProvider
