import { FaUserFriends, FaFileAlt, FaUser, FaCogs } from "react-icons/fa"
import { Link, useRouteMatch } from "react-router-dom"
import { useSessionContext } from "../user/SessionContext"

const SideBar = () => {
  const { url } = useRouteMatch()
  const { userType } = useSessionContext()

  return (
    <div
      className="fixed top-0 left-0 h-screen w-16 m-0
                    flex flex-col 
                    bg-blue-900 text-white shadow-lg"
    >
      <div className="mb-auto">
        <h1 className="text-center text-4xl text-white font-bold my-4">
          <Link to="/">R</Link>
        </h1>

        <SideBarIcon
          icon={<FaUserFriends size="20" />}
          text="досье"
          link={url + "/dossier"}
        />
        <SideBarIcon
          icon={<FaFileAlt size="20" />}
          text="встречи"
          link={url + "/meetings"}
        />
      </div>

      <div>
        {userType !== "admin" || (
          <SideBarIcon
            icon={<FaCogs size="20" />}
            text="администрирование"
            link={url + "/manage"}
          />
        )}
        <Divider />
        <SideBarIcon
          icon={<FaUser size="20" />}
          text="мой профиль"
          link={url + "/profile"}
        />
      </div>
    </div>
  )
}

const Divider = () => (
  <hr
    className="bg-gray-200 dark:bg-gray-800 border
      border-gray-200 dark:border-gray-800
        rounded-full mx-2"
  />
)

const SideBarIcon = ({ icon, text, link = "" }) => (
  <Link
    to={link}
    className="relative flex items-center justify-center 
      h-12 w-12 mt-2 mb-2 mx-auto  
    bg-slate-300 hover:bg-slate-600
    text-slate-500 hover:text-white
      hover:rounded-xl rounded-3xl
      transition-all duration-300 ease-linear
      cursor-pointer shadow-lg group"
  >
    {icon}

    <span
      className="absolute w-auto p-2 m-2 min-w-max left-14
        rounded-md shadow-md
        text-white bg-blue-900 
        font-bold
        transition-all duration-100 scale-0 origin-left
        group-hover:scale-100"
    >
      {text}
    </span>
  </Link>
)

export default SideBar
