import axios from "axios"
import { useState } from "react"
import { useRouteMatch } from "react-router-dom"
import { Link } from "react-router-dom"
import { LockClosedIcon } from "@heroicons/react/solid"
import Input from "../helpers/Input"
import Select from "../helpers/Select"
import Button from "../helpers/Button"
import { API_URL, userTypesOptions } from "../util"
import { useSessionContext } from "./SessionContext"
import { toast } from "react-toastify"
import { handleError } from "./error"

const SignIn = () => {
  const { url } = useRouteMatch()
  const { login } = useSessionContext()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [selectedUserType, setSelectedUserType] = useState("")

  const signIn = async e => {
    e.preventDefault()

    try {
      const data = { email, password, userType: selectedUserType }
      const resp = await axios.post(`${API_URL}/signIn`, data)

      const { status, token, code } = resp.data
      if (status === "error") return handleError(code)

      login(token)
    } catch (error) {
      toast.error("Ошибка сервера")
    }
  }

  return (
    <>
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Войти в учётную запись
      </h2>
      <p className="mt-2 text-center text-sm text-gray-600">
        Или&nbsp;
        <Link
          to={url + "/signUp"}
          className="font-medium text-blue-600 hover:text-blue-500"
        >
          зарегистрировать новую
        </Link>
      </p>

      <form className="mt-8 space-y-6" action="#" method="POST">
        <div className="rounded-md shadow-sm -space-y-px">
          <Input
            name="email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            classesString="rounded-t-md"
            placeholder="Email адрес"
          />

          <Input
            name="password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Пароль"
          />

          <Select
            selectedOption={selectedUserType}
            setSelectedOption={setSelectedUserType}
            placeholder="Выберите тип пользователя"
            options={userTypesOptions}
            classesString="rounded-b-md"
          />
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="remember-me"
              type="checkbox"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label
              htmlFor="remember-me"
              className="ml-2 block text-sm text-gray-900"
            >
              Запомнить меня
            </label>
          </div>

          <div className="text-sm">
            <Link
              to={url + "/reset"}
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Забыли пароль?
            </Link>
          </div>
        </div>

        <div>
          <Button type="submit" color="link" onClick={signIn}>
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon
                className="h-5 w-5 text-blue-500 group-hover:text-blue-400"
                aria-hidden="true"
              />
            </span>
            Вход
          </Button>
        </div>
      </form>
    </>
  )
}

export default SignIn
