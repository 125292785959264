import PageTitle from "../../helpers/PageTitle"
import OrganizationContextProvider from "./OrganizationContext"
import OrganizationList from "./OrganizationList"
import OrganizationNew from "./OrganizationNew"

const OrganizationView = () => {
  return (
    <OrganizationContextProvider>
      <PageTitle>Организации</PageTitle>

      <OrganizationNew />

      <OrganizationList />
    </OrganizationContextProvider>
  )
}

export default OrganizationView
