const PageTitle = ({ children }) => {
  return (
    <div className="flex items-center justify-center h-16 m-0 p-0 w-full">
      <h1 className="text-4xl overline tracking-wider font-bold text-slate-600 ml-auto mr-0 align-middle pt-4">
        {children}
      </h1>
    </div>
  )
}

export default PageTitle
