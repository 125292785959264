import Box from "../../helpers/Box"
import Input from "../../helpers/Input"
import Select from "react-select"
import { useOrganizationOptions } from "./WorkAtContext"
import { useParams } from "react-router-dom"
import { handleError } from "../../user/error"
import { useState } from "react"
import Button from "../../helpers/Button"
import axios from "axios"
import { API_URL } from "../../util"
import { usePersonContext } from "./PersonContext"
import { toast } from "react-toastify"

const actualOptions = [
  { value: "yes", label: "Да" },
  { value: "no", label: "Нет" },
]

const WorkAtNew = () => {
  const { personRefetch, refetch } = usePersonContext()
  const { orgOptions, loading } = useOrganizationOptions()
  const { person_id } = useParams()

  const [selectedOrg, setSelectedOrg] = useState(null)
  const [selectedActual, setSelectedActual] = useState(null)
  const [position, setPosition] = useState("")
  const [startYear, setStartYear] = useState("")
  const [endYear, setEndYear] = useState("")

  const toggleEndYear = () => {
    if (endYear === -1) setEndYear("")
    else setEndYear(-1)
  }

  const clearForm = () => {
    setSelectedOrg(null)
    setSelectedActual(null)
    setPosition("")
    setStartYear("")
    setEndYear("")
  }

  const addWorkAt = async () => {
    try {
      const data = {
        work_at: {
          person_id,
          organization_id: selectedOrg?.value,
          position,
          start_year: Number(startYear),
          end_year: Number(endYear),
          is_actual: selectedActual?.value === "yes",
        },
      }
      const resp = await axios.post(`${API_URL}/work_at`, data)
      const { status, code } = resp.data

      if (status === "error")
        return handleError(
          code,
          "Убедитесь, что все обязательные поля заполнены"
        )

      refetch()
      personRefetch()
      clearForm()
      toast.success("Занятость успешно добавлена")
    } catch (error) {
      handleError()
    }
  }

  return (
    <Box>
      <div className="grid grid-cols-2 gap-2">
        <Input
          labelText="Год начала работы"
          placeholder="Укажите год..."
          helpText="необязательное поле"
          value={startYear}
          onChange={e => setStartYear(e.target.value)}
          classesContainerString="grow"
        />

        <div className="grid grid-cols-4">
          <Input
            labelText="Год завершения работы"
            placeholder={endYear === -1 ? "н.в." : "Укажите год..."}
            helpText="необязательное поле"
            value={endYear === -1 ? "по настоящее время" : endYear}
            onChange={e => setEndYear(e.target.value)}
            classesContainerString="col-span-3"
            disabled={endYear === -1}
          />

          <div
            className={
              "cursor-pointer m-auto text-lg font-semibold " +
              (endYear === -1 ? "text-slate-700" : "text-slate-400")
            }
            onClick={toggleEndYear}
          >
            н.в.
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-2 mt-3">
        <div className="flex flex-col justify-center">
          <label className="form-label mb-1 text-gray-700 font-semibold">
            Организация
          </label>

          <Select
            options={orgOptions}
            value={selectedOrg}
            onChange={setSelectedOrg}
            placeholder="Выберите организацию..."
            isLoading={loading}
            isClearable
          />

          <p className="mt-0 text-sm text-gray-500 text-right">
            обязательное поле
          </p>
        </div>

        <Input
          labelText="Должность"
          placeholder="Укажите должность..."
          helpText="обязательное поле"
          value={position}
          onChange={e => setPosition(e.target.value)}
        />

        <div className="flex flex-col justify-center">
          <label className="form-label mb-1 text-gray-700 font-semibold">
            Актуальность
          </label>

          <Select
            options={actualOptions}
            value={selectedActual}
            onChange={setSelectedActual}
            placeholder="Является актуальной..."
            isClearable
          />

          <p className="mt-0 text-sm text-gray-500 text-right">
            обязательное поле
          </p>
        </div>
      </div>

      <div className="flex flex-row gap-2 justify-end mt-3">
        <Button size="" color="link" onClick={addWorkAt}>
          Добавить
        </Button>
      </div>
    </Box>
  )
}

export default WorkAtNew
