import { Link } from "react-router-dom"
import Input from "../helpers/Input"
import Select from "../helpers/Select"
import Button from "../helpers/Button"
import { useState } from "react"
import axios from "axios"
import { API_URL, userTypesOptions } from "../util"
import { toast } from "react-toastify"
import { handleError } from "./error"
import { useHistory } from "react-router"
import { UserAddIcon } from "@heroicons/react/solid"

const SignUp = () => {
  const history = useHistory()
  const [code, setCode] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordRep, setPasswordRep] = useState("")
  const [name, setName] = useState("")
  const [secondName, setSecondName] = useState("")
  const [surname, setSurname] = useState("")
  const [division, setDivision] = useState("")
  const [position, setPosition] = useState("")
  const [selectedUserType, setSelectedUserType] = useState("")

  const signUp = async e => {
    e.preventDefault()

    if (password !== passwordRep) return toast.error("Пароли должны совпадать")

    try {
      const fullName = `${surname.trim()} ${name.trim()} ${secondName.trim()}`
      const data = {
        user: {
          code,
          email,
          password,
          userType: selectedUserType,
          fullName,
          division,
          position,
        },
      }
      const resp = await axios.post(`${API_URL}/users`, data)

      const { status, code: errorCode } = resp.data
      if (status === "error")
        return handleError(errorCode, "Убедитесь, что заполнены все поля")

      toast.success("Пользователь успешно создан")
      history.push("..")
    } catch (error) {
      toast.error("Ошибка сервера")
    }
  }

  return (
    <>
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Создать учётную запись
      </h2>
      <p className="mt-2 text-center text-sm text-gray-600">
        Или&nbsp;
        <Link
          to=".."
          className="font-medium text-blue-600 hover:text-indigo-500"
        >
          войти в существующую
        </Link>
      </p>

      <form className="mt-8 space-y-6" action="#" method="POST">
        <div className="rounded-md shadow-sm -space-y-px">
          <Input
            name="code"
            type="text"
            value={code}
            onChange={e => setCode(e.target.value)}
            classesString="rounded-t-md"
            placeholder="Код приглашения"
          />

          <Input
            name="email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email адрес"
          />

          <div className="flex flex-row">
            <Input
              name="password"
              type="password"
              classesString="border-r-0"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Пароль"
            />
            <Input
              name="passwordRep"
              type="password"
              value={passwordRep}
              onChange={e => setPasswordRep(e.target.value)}
              placeholder="Пароль повтор"
            />
          </div>

          <div className="flex flex-row">
            <Input
              name="surname"
              type="text"
              classesString="border-r-0"
              value={surname}
              onChange={e => setSurname(e.target.value)}
              placeholder="Фамилия"
            />
            <Input
              name="name"
              type="text"
              classesString="border-r-0"
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="Имя"
            />
            <Input
              name="secondName"
              type="text"
              value={secondName}
              onChange={e => setSecondName(e.target.value)}
              placeholder="Отчество"
            />
          </div>

          <div className="flex flex-row">
            <Input
              name="division"
              type="text"
              value={division}
              classesString="border-r-0"
              onChange={e => setDivision(e.target.value)}
              placeholder="Подразделение"
            />
            <Input
              name="position"
              type="text"
              value={position}
              onChange={e => setPosition(e.target.value)}
              placeholder="Должность"
            />
          </div>

          <Select
            selectedOption={selectedUserType}
            setSelectedOption={setSelectedUserType}
            placeholder="Выберите тип пользователя"
            options={userTypesOptions}
            classesString="rounded-b-md"
          />
        </div>

        <div>
          <Button type="submit" color="link" onClick={signUp}>
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <UserAddIcon
                className="h-5 w-5 text-blue-500 group-hover:text-blue-400"
                ria-hidden="true"
              />
            </span>
            Регистрация
          </Button>
        </div>
      </form>
    </>
  )
}

export default SignUp
