export const API_URL = process.env.REACT_APP_API_URL

export const orgTypes = {
  admin: "Административная",
  commercial: "Коммерческая",
  educative: "Образовательная",
}

export const userTypes = {
  admin: "Администратор",
  editor: "Редактор",
  user: "Пользователь",
}

export const userTypesOptions = Object.entries(userTypes).map(([k, v]) => ({
  value: k,
  label: v,
}))
