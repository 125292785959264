const Input = ({
  value,
  onChange,
  classesString = "",
  classesContainerString = "",
  labelText = "",
  helpText = "",
  ...rest
}) => (
  <div className={"flex flex-col justify-center " + classesContainerString}>
    {!labelText?.length || (
      <label className="form-label mb-1 text-gray-700 font-semibold">
        {labelText}
      </label>
    )}

    <input
      value={value}
      onChange={onChange}
      {...rest}
      className={
        "appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm " +
        classesString
      }
    />

    {!helpText?.length || (
      <p className="mt-0 text-sm text-gray-500 text-right">{helpText}</p>
    )}
  </div>
)

export default Input
