import MenuBar, { MenuEntry } from "../navbars/MenuBar"
import ContentContainer from "../helpers/ContentContainer"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import InviteView from "./invites/InviteView"

const ManageView = () => {
  const { url, path } = useRouteMatch()

  return (
    <>
      <MenuBar>
        <MenuEntry text="Коды приглашения" link={url + "/invite"} />
        <MenuEntry text="Пользователи" link={url + "/user"} />
      </MenuBar>

      <ContentContainer>
        <Switch>
          <Route exact path={path}>
            <Redirect to={path + "/invite"} />
          </Route>

          <Route path={path + "/invite"}>
            <InviteView />
          </Route>

          <Route path={path + "/user"}>USERS</Route>
        </Switch>
      </ContentContainer>
    </>
  )
}

export default ManageView
