import MenuBar, { ExitEntry, MenuEntry } from "../navbars/MenuBar"
import ContentContainer from "../helpers/ContentContainer"
import { useRouteMatch } from "react-router-dom"

const ProfileView = () => {
  const { url } = useRouteMatch()

  return (
    <>
      <MenuBar>
        <MenuEntry text="Мой профиль" link={url + "/"} />
        <ExitEntry />
      </MenuBar>

      <ContentContainer>MY PROFILE</ContentContainer>
    </>
  )
}

export default ProfileView
