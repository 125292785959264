const Button = ({ children, color = "", size = "w-full", ...rest }) => {
  let colorStyle = ""
  switch (color) {
    case "link":
      colorStyle =
        "text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
      break
    default:
      colorStyle =
        "bg-gray-300 hover:bg-gray-500 hover:text-white focus:ring-grey-500"
  }

  return (
    <button
      className={`group relative ${size} flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${colorStyle}`}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button
