import Avatar from "react-avatar-edit"

const AvatarCrop = ({ preview, setPreview, labelText = "" }) => {
  return (
    <>
      {!labelText?.length || (
        <label className="form-label mb-1 text-gray-700 font-semibold">
          {labelText}
        </label>
      )}

      <div className="flex flex-row">
        <Avatar
          width={440}
          height={250}
          onCrop={setPreview}
          onClose={() => setPreview(null)}
        />

        <div className="flex items-center mx-auto">
          {!preview?.length || (
            <img
              alt=""
              style={{ width: "150px", height: "150px" }}
              src={preview}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default AvatarCrop
