import { Switch } from "react-router"
import { useRouteMatch } from "react-router-dom"
import { Route } from "react-router-dom"
import SignIn from "./SignIn"
import SignUp from "./SignUp"

const AuthArea = () => {
  const { path } = useRouteMatch()

  return (
    <div className="w-screen min-h-screen bg-blue-900 py-10">
      <div className="container max-w-xl mx-auto bg-white rounded-lg shadow p-8 m-10">
        <h1 className="text-center text-4xl text-blue-900 font-bold font-blue-600">
          &lt;R&gt;
        </h1>

        <Switch>
          <Route exact path={path} component={SignIn} />

          <Route exact path={path + "/signUp"} component={SignUp} />
        </Switch>
      </div>
    </div>
  )
}

export default AuthArea
