import { FaCircleNotch, FaSignOutAlt } from "react-icons/fa"
import { Link } from "react-router-dom"
import { useSessionContext } from "../user/SessionContext"

const MenuBar = ({ children, title = "Доступные разделы" }) => {
  return (
    <div className="w-80 h-screen m-0 ml-16 bg-slate-200 overflow-hidden shadow-lg">
      <MenuBlock text={title} />

      {children}
    </div>
  )
}

const MenuBlock = ({ text }) => (
  <div className="flex items-center justify-center h-16 m-0 p-0">
    <h5 className="text-lg tracking-wider font-bold text-gray-600 mr-auto ml-4 my-auto align-middle">
      {text}
    </h5>
  </div>
)

const Entry = ({
  children,
  mainColor = "gray",
  hoverColor = "blue",
  icon = "notch",
}) => {
  let jsxIcon = (
    <FaCircleNotch size="20" className={`text-${mainColor}-500 mr-2`} />
  )
  switch (icon) {
    case "exit":
      jsxIcon = (
        <FaSignOutAlt size="20" className={`text-${mainColor}-500 mr-2`} />
      )
      break
    default:
      break
  }

  return (
    <div className="flex flex-row items-center justify-evenly mt-1 mr-auto ml-2 transition duration-300 ease-in-out">
      {jsxIcon}
      <h5
        className={`text-${mainColor}-500 font-semibold tracking-wide mr-auto transition duration-300 ease-in-out hover:text-${hoverColor}-700 cursor-pointer`}
      >
        {children}
      </h5>
    </div>
  )
}

export const MenuEntry = ({ text, link = "" }) => {
  return (
    <Entry>
      <Link to={link}>{text}</Link>
    </Entry>
  )
}

export const ExitEntry = () => {
  const { logout } = useSessionContext()

  return (
    <Entry mainColor="red" hoverColor="red" icon="exit">
      <span onClick={logout}>Выход</span>
    </Entry>
  )
}

export default MenuBar
