import { Switch, Route, Redirect, useLocation } from "react-router"
import RestrictedArea from "./RestrictedArea"
import AuthArea from "./user/AuthArea"
import { useSessionContext } from "./user/SessionContext"

function App() {
  const { isSigned } = useSessionContext()
  const { pathname } = useLocation()

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

      <Route exact path="/">
        {!isSigned ? <Redirect to="/auth" /> : <Redirect to="/r" />}
      </Route>

      <Route path="/r">
        {isSigned ? <RestrictedArea /> : <Redirect to="/auth" />}
      </Route>

      <Route path="/auth">
        {isSigned ? <Redirect to="/" /> : <AuthArea />}
      </Route>
    </Switch>
  )
}

export default App
