import { useState } from "react"
import AddNewBox from "../../helpers/AddNewBox"
import Box from "../../helpers/Box"
import Button from "../../helpers/Button"
import Input from "../../helpers/Input"
import Select from "../../helpers/Select"
import Textarea from "../../helpers/Textarea"
import { useSessionContext } from "../../user/SessionContext"
import { orgTypes } from "../../util"
import { useOrganizationContext } from "./OrganizationContext"

const OrganizationNew = () => {
  const { create } = useOrganizationContext()
  const { user } = useSessionContext()

  const [mode, setMode] = useState("view")
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [selectedType, setSelectedType] = useState("")

  const orgTypeOptions = Object.entries(orgTypes).map(([key, value]) => ({
    value: key,
    label: value,
  }))

  const clearForm = () => {
    setName("")
    setDescription("")
    setSelectedType("")

    setMode("view")
  }

  if (mode === "view")
    return (
      <AddNewBox
        text="Добавить новую организацию"
        onClick={() => setMode("add")}
      />
    )

  return (
    <Box>
      <div className="flex flex-row mb-3 gap-4">
        <div className="w-full">
          <Input
            labelText="Наименование"
            helpText="обязательное поле"
            placeholder="ООО Вектор"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>

        <div className="w-full">
          <Select
            labelText="Тип организации"
            helpText="обязательное поле"
            placeholder="Выберите тип организации"
            options={orgTypeOptions}
            selectedOption={selectedType}
            setSelectedOption={setSelectedType}
          />
        </div>
      </div>

      <Textarea
        labelText="Описание"
        helpText="необязательное поле"
        placeholder="Краткое описание организации (любая информация о ней)"
        value={description}
        onChange={e => setDescription(e.target.value)}
      />

      <div className="flex flex-row gap-2 justify-end mt-3">
        <Button size="" onClick={() => setMode("view")}>
          Отменить
        </Button>
        <Button
          size=""
          color="link"
          onClick={() =>
            create(
              {
                name,
                description,
                type: selectedType,
                author_id: user?.id,
              },
              clearForm
            )
          }
        >
          Добавить
        </Button>
      </div>
    </Box>
  )
}

export default OrganizationNew
