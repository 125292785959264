import { Route, Switch, useRouteMatch } from "react-router-dom"
import PageTitle from "../../helpers/PageTitle"
import PersonContextProvider from "./PersonContext"
import PersonList from "./PersonList"
import PersonNew from "./PersonNew"
import PersonPage from "./PersonPage"

const PersonView = () => {
  const { path } = useRouteMatch()

  return (
    <PersonContextProvider>
      <PageTitle>Персоны</PageTitle>

      <Switch>
        <Route exact path={path}>
          <PersonNew />

          <PersonList />
        </Route>

        <Route path={path + "/:person_id"}>
          <PersonPage />
        </Route>
      </Switch>
    </PersonContextProvider>
  )
}

export default PersonView
