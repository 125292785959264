import dayjs from "dayjs"
import EmptyList from "../../helpers/EmptyList"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import { userTypes } from "../../util"
import { useInviteContext } from "./InviteContext"

const InviteList = () => {
  const { invites, loading } = useInviteContext()

  if (loading) return <LoadingSpinner />

  if (!invites?.length) return <EmptyList />

  return (
    <>
      <div className="shadow-lg mt-5">
        <table className="table-auto rounded-lg w-full text-left text-slate-500">
          <thead className="text-slate-700 uppercase bg-gray-100">
            <tr>
              <th className="py-3 px-6">Код</th>
              <th className="py-3">Email</th>
              <th className="py-3">Тип пользователя</th>
              <th className="py-3">Использован</th>
              <th className="py-3">Создан</th>
            </tr>
          </thead>
          <tbody>
            {(invites || []).map(invite => (
              <tr className="border-b bg-white" key={invite?.id}>
                <th className="py-4 px-6 text-lg font-semibold text-slate-700 whitespace-nowrap">
                  {invite?.code}
                </th>
                <td className="lowercase">{invite?.email}</td>
                <td className="lowercase">{userTypes?.[invite?.userType]}</td>
                <td className="lowercase">{invite?.is_used ? "да" : "нет"}</td>
                <td className="lowercase">
                  {dayjs(invite?.inserted_at).format("DD.MM.YYYY HH:mm:ss")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default InviteList
