import { usePersonContext } from "./PersonContext"

const WorkAtList = () => {
  const { person } = usePersonContext()

  const works = person?.work_at?.sort((a, b) => b?.start_year - a?.start_year)

  return (
    <div className="shadow-lg mt-5">
      <table className="table-auto rounded-lg w-full text-left text-slate-500">
        <thead className="text-slate-700 uppercase bg-gray-100">
          <tr>
            <th className="py-3 px-6">Должность</th>
            <th className="py-3">Место</th>
            <th className="py-3">Начало</th>
            <th className="py-3">Конец</th>
          </tr>
        </thead>
        <tbody>
          {(works || []).map(work => (
            <tr className="border-b bg-white" key={work?.id}>
              <th
                className={
                  "py-4 px-6 text-lg font-semibold " +
                  (work?.is_actual ? "text-blue-600" : "text-slate-700")
                }
              >
                {work?.position}
              </th>
              <td>{work?.organization?.name}</td>
              <td className="lowercase">
                {work?.start_year === 0 ? "неизвестно" : work?.start_year}
              </td>
              <td className="lowercase">
                {work?.end_year === -1
                  ? "н.в"
                  : work?.end_year === 0
                  ? "неизвестно"
                  : work?.end_year}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default WorkAtList
