const Select = ({
  options,
  setSelectedOption,
  selectedOption,
  placeholder = "Выберите из списка...",
  classesString = "",
  labelText = "",
  helpText = "",
  ...rest
}) => (
  <div className="flex flex-col justify-center">
    {!labelText?.length || (
      <label className="form-label mb-1 text-gray-700 font-semibold">
        {labelText}
      </label>
    )}
    <select
      aria-label="Select component"
      value={selectedOption}
      onChange={e => setSelectedOption(e.target.value)}
      className={
        "appearance-none block w-full px-3 py-2 text-gray-500 bg-white bg-clip-padding bg-no-repeat border border-gray-300 rounded-none transition ease-in-out focus:text-gray-900 focus:bg-white focus:border-blue-500 focus:outline-none sm:text-sm m-0 " +
        classesString
      }
      {...rest}
    >
      <option value="" className="text-gray-500" disabled hidden>
        {placeholder}...
      </option>
      {(options || []).map(opt => (
        <option key={opt?.value} value={opt?.value}>
          {opt?.label}
        </option>
      ))}
    </select>

    {!helpText?.length || (
      <p className="mt-0 text-sm text-gray-500 text-right">{helpText}</p>
    )}
  </div>
)

export default Select
