import { useState } from "react"
import { Link, useHistory, useRouteMatch } from "react-router-dom"
import AvatarHelper from "../../helpers/AvatarHelper"
import EmptyList from "../../helpers/EmptyList"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import ViewSelect from "../../helpers/ViewSelect"
import { API_URL } from "../../util"
import { fullName, usePersonContext } from "./PersonContext"

const PersonList = () => {
  const { persons, loading } = usePersonContext()

  const [viewMode, setViewMode] = useState("cards")

  if (loading) return <LoadingSpinner />

  if (!persons?.length) return <EmptyList />

  return (
    <>
      <ViewSelect viewMode={viewMode} setViewMode={setViewMode} />

      {viewMode !== "cards" || <PersonCardList persons={persons} />}

      {viewMode !== "list" || <PersonTableList persons={persons} />}
    </>
  )
}

const PersonTableList = ({ persons }) => {
  const { url } = useRouteMatch()

  return (
    <div className="shadow-lg">
      <table className="table-auto rounded-lg w-full text-left text-slate-500">
        <thead className="text-slate-700 uppercase bg-gray-100">
          <tr>
            <th className="py-3 px-6">Имя</th>
            <th className="py-3">Организации</th>
          </tr>
        </thead>
        <tbody>
          {(persons || []).map(person => (
            <tr className="border-b bg-white" key={person?.id}>
              <th className="py-4 px-6 text-lg font-semibold text-slate-700 hover:text-blue-900 whitespace-nowrap cursor-pointer">
                <Link to={url + "/" + person?.id}>{fullName(person)}</Link>
              </th>
              <td>...</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const PersonCardList = ({ persons }) => {
  const { url } = useRouteMatch()
  const history = useHistory()

  return (
    <div className="grid gap-5 xl:grid-cols-2">
      {(persons || []).map(person => (
        <div
          className="flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:w-full hover:bg-gray-100 cursor-pointer"
          key={person?.id}
          onClick={() => history.push(url + "/" + person?.id)}
        >
          {person?.image_url ? (
            <img
              className="object-cover md:h-32 p-4"
              src={`${API_URL}/uploads/portraits/${person?.image_url}`}
              alt=""
            />
          ) : (
            <AvatarHelper />
          )}
          <div className="flex flex-col p-4 leading-normal">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-slate-700">
              {fullName(person)}
            </h5>
            {/* <p className="mb-3 font-normal text-slate-500 dark:text-gray-400">
                Here are the biggest enterprise technology acquisitions of 2021
                so far, in reverse chronological order.
              </p> */}
          </div>
        </div>
      ))}
    </div>
  )
}

export default PersonList
