import { FaPlus } from "react-icons/fa"
import Box from "./Box"

const AddNewBox = ({ text, onClick }) => {
  return (
    <Box>
      <div
        className="flex flex-row items-center justify-center cursor-pointer hover:text-blue-900 py-3"
        onClick={onClick}
      >
        <FaPlus className="mr-2" size={25} />
        <span className="text-lg font-semibold">{text}</span>
      </div>
    </Box>
  )
}

export default AddNewBox
