import { useEffect } from "react"
import { useParams } from "react-router-dom"
import AvatarHelper from "../../helpers/AvatarHelper"
import Box from "../../helpers/Box"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import { API_URL } from "../../util"
import { fullName, usePersonContext } from "./PersonContext"
import WorkAtList from "./WorkAtList"
import WorkAtNew from "./WorkAtNew"

const PersonPage = () => {
  const { person_id } = useParams()
  const { setPersonID, person, personLoading } = usePersonContext()

  useEffect(() => {
    setPersonID(person_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (personLoading) return <LoadingSpinner />

  return (
    <div>
      <Box>
        <h1 className="text-2xl font-bold text-slate-700">
          {fullName(person)}
        </h1>
      </Box>

      <div className="grid grid-cols-4 gap-4">
        <Box>
          {person?.image_url ? (
            <img
              className="object-cover md:h-48 p-4 mx-auto"
              src={`${API_URL}/uploads/portraits/${person?.image_url}`}
              alt=""
            />
          ) : (
            <AvatarHelper />
          )}
        </Box>

        <div className="col-span-3">
          {!person?.description?.length || (
            <Box wrapped>{person?.description}</Box>
          )}

          <WorkAtList />

          <WorkAtNew />
        </div>
      </div>
    </div>
  )
}

export default PersonPage
