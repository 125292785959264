import { FaGripHorizontal, FaList } from "react-icons/fa"

const ViewSelect = ({ viewMode, setViewMode }) => {
  const activeStyle = "bg-slate-700 text-white"
  const generalStyle =
    "bg-white text-slate-700 hover:bg-slate-500 hover:text-white"

  return (
    <div className="flex flex-row justify-between mt-3 mb-2">
      <div></div>

      <div className="flex justify-end">
        <button
          className={`${
            viewMode === "cards" ? activeStyle : generalStyle
          } group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-l-md`}
          onClick={() => setViewMode("cards")}
        >
          <FaGripHorizontal size="20" />
        </button>
        <button
          className={`${
            viewMode === "list" ? activeStyle : generalStyle
          } group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-r-md`}
          onClick={() => setViewMode("list")}
        >
          <FaList size="20" />
        </button>
      </div>
    </div>
  )
}

export default ViewSelect
