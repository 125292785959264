import axios from "axios"
import { useEffect } from "react"
import { useState } from "react"
import { createContext, useContext } from "react"
import { toast } from "react-toastify"
import { handleError } from "../../user/error"
import { API_URL } from "../../util"

export const fullName = person =>
  `${person?.last_name} ${person?.name} ${person?.second_name || ""}`

export const PersonContext = createContext()

const useOrganizations = () => {
  const [persons, setPersons] = useState([])
  const [personID, setPersonID] = useState(-1)
  const [person, setPerson] = useState({})
  const [personLoading, setPersonLoading] = useState(true)
  const [loading, setLoading] = useState(true)
  const [nr, setNR] = useState(true)
  const [personNr, setPersonNR] = useState(true)

  useEffect(() => {
    // force person update with loading on personID change
    setPersonLoading(true)
    setPersonNR(true)
  }, [personID])

  useEffect(() => {
    const fetchPersons = async () => {
      try {
        const resp = await axios.get(`${API_URL}/persons`)
        const { status, code, data } = resp.data

        if (status === "error") return handleError(code)

        setPersons(data)
      } catch (error) {
        handleError()
      }
    }

    if (nr) {
      fetchPersons()
      setLoading(false)
      setNR(false)
    }
  }, [nr])

  useEffect(() => {
    const fetchPerson = async () => {
      try {
        const resp = await axios.get(`${API_URL}/persons/${personID}`)
        const { status, code, data } = resp.data

        if (status === "error") return handleError(code)

        setPerson(data)
      } catch (error) {
        handleError()
      }
    }

    if (personNr && personID > 0) {
      fetchPerson()
      setPersonLoading(false)
      setPersonNR(false)
    }
  }, [personNr, personID])

  const refetch = (needsLoading = false) => {
    if (needsLoading) setLoading(true)

    setNR(true)
  }

  const personRefetch = (needsLoading = false) => {
    if (needsLoading) setPersonLoading(true)

    setPersonNR(true)
  }

  return {
    persons,
    person,
    loading,
    personLoading,
    refetch,
    personRefetch,
    setPersonID,
  }
}

const PersonContextProvider = ({ children }) => {
  const {
    persons,
    person,
    loading,
    personLoading,
    refetch,
    personRefetch,
    setPersonID,
  } = useOrganizations()

  const create = async (data, clearForm) => {
    try {
      const reqData = { person: data }
      const resp = await axios.post(`${API_URL}/persons`, reqData)
      const { status, code } = resp.data

      if (status === "error")
        return handleError(
          code,
          "Убедитесь, что все обязательные поля заполнены и такая персона уже не добавлена в систему"
        )

      refetch()
      clearForm()
      toast.success("Персона успешно добавлена")
    } catch (error) {
      handleError()
    }
  }

  const value = {
    create,
    persons,
    person,
    loading,
    personLoading,
    refetch,
    personRefetch,
    setPersonID,
  }

  return (
    <PersonContext.Provider value={value}>{children}</PersonContext.Provider>
  )
}

export const usePersonContext = () => {
  const context = useContext(PersonContext)

  return context
}

export default PersonContextProvider
