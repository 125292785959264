import ContentContainer from "../helpers/ContentContainer"

const HomeView = () => {
  return (
    <div className="ml-16 w-full">
      <ContentContainer>HOME HOME HOME HOME HOME </ContentContainer>
    </div>
  )
}

export default HomeView
