import { useState } from "react"
import AddNewBox from "../../helpers/AddNewBox"
import AvatarCrop from "../../helpers/AvatarCrop"
import Box from "../../helpers/Box"
import Button from "../../helpers/Button"
import Input from "../../helpers/Input"
import Textarea from "../../helpers/Textarea"
import { useSessionContext } from "../../user/SessionContext"
import { usePersonContext } from "./PersonContext"

const PersonNew = () => {
  const { create } = usePersonContext()
  const { user } = useSessionContext()

  const [mode, setMode] = useState("view")
  const [name, setName] = useState("")
  const [secondName, setSecondName] = useState("")
  const [lastName, setLastName] = useState("")
  const [description, setDescription] = useState("")
  const [image, setImage] = useState(null)

  const clearForm = () => {
    setName("")
    setSecondName("")
    setLastName("")
    setDescription("")
    setImage(null)

    setMode("view")
  }

  if (mode === "view")
    return (
      <AddNewBox text="Добавить новую персону" onClick={() => setMode("add")} />
    )

  return (
    <Box>
      <div className="flex flex-row mb-3 gap-4">
        <div className="w-full">
          <Input
            labelText="Фамилия"
            helpText="обязательное поле"
            placeholder="Иванов"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />

          <Input
            labelText="Имя"
            helpText="обязательное поле"
            placeholder="Иван"
            value={name}
            onChange={e => setName(e.target.value)}
          />

          <Input
            labelText="Отчество"
            helpText="необязательное поле"
            placeholder="Иванович"
            value={secondName}
            onChange={e => setSecondName(e.target.value)}
          />
        </div>

        <div className="w-full">
          <Textarea
            labelText="Описание"
            helpText="необязательное поле"
            placeholder="Краткое описание персоны (любая информация о ней)"
            value={description}
            rows={10}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
      </div>

      <AvatarCrop preview={image} setPreview={setImage} labelText="Портрет" />

      <div className="flex flex-row gap-2 justify-end mt-3">
        <Button size="" onClick={() => setMode("view")}>
          Отменить
        </Button>
        <Button
          size=""
          color="link"
          onClick={() =>
            create(
              {
                name,
                second_name: secondName,
                last_name: lastName,
                description,
                author_id: user?.id,
                preview: image,
              },
              clearForm
            )
          }
        >
          Добавить
        </Button>
      </div>
    </Box>
  )
}

export default PersonNew
