import { createContext, useContext, useState } from "react"
import axios from "axios"
import { API_URL } from "../util"
import { handleError } from "./error"

export const SessionContext = createContext()

const tokenFromStorage = localStorage.getItem("token") || ""
if (tokenFromStorage?.length)
  axios.defaults.headers.common["Authorization"] = "Bearer " + tokenFromStorage

const SessionContextProvider = ({ children }) => {
  const [token, setToken] = useState(tokenFromStorage)
  const [user, setUser] = useState(null)

  const login = newToken => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + newToken
    localStorage.setItem("token", newToken)
    setToken(newToken)
  }

  const logout = async () => {
    localStorage.removeItem("token")
    axios.defaults.headers.common["Authorization"] = ""
    setToken("")
    setUser(null)
  }

  const getProfile = async () => {
    try {
      const resp = await axios.get(`${API_URL}/profile`)
      const { status, data } = resp.data

      if (status === "error")
        return handleError("", "Ошибка авторизации, обновите страницу")

      setUser(data)
    } catch (error) {
      handleError("")
    }
  }

  const value = {
    token,
    login,
    logout,
    isSigned: token.length > 0,
    user,
    userType: user?.userType,
    getProfile,
  }

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  )
}

export const useSessionContext = () => {
  const context = useContext(SessionContext)

  return context
}

export default SessionContextProvider
