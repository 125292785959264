import EmptyList from "../../helpers/EmptyList"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import { orgTypes } from "../../util"
import { useOrganizationContext } from "./OrganizationContext"

const OrganizationList = () => {
  const { orgs, loading } = useOrganizationContext()

  if (loading) return <LoadingSpinner />

  if (!orgs?.length) return <EmptyList />

  return (
    <>
      <div className="shadow-lg mt-5">
        <table className="table-auto rounded-lg w-full text-left text-slate-500">
          <thead className="text-slate-700 uppercase bg-gray-100">
            <tr>
              <th className="py-3 px-6">Наименование</th>
              <th className="py-3">Тип</th>
            </tr>
          </thead>
          <tbody>
            {(orgs || []).map(org => (
              <tr className="border-b bg-white" key={org?.id}>
                <th className="py-4 px-6 text-lg font-semibold text-slate-700 hover:text-blue-900 whitespace-nowrap cursor-pointer">
                  {org?.name}
                </th>
                <td className="lowercase">{orgTypes?.[org?.type]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default OrganizationList
