const LoadingSpinner = () => {
  return (
    <div className="flex flex-col items-center justify-center pt-16 pb-10 bg-gray-100 rounded-lg shadow-lg mt-4">
      <div className="w-40 h-40 border-t-4 border-b-4 border-slate-700 rounded-full animate-bounce"></div>
      <span className="mt-5 text-xl font-semibold text-slate-700">
        Подождите, идёт загрузка...
      </span>
    </div>
  )
}

export default LoadingSpinner
